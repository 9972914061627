import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import { MdContacts } from "react-icons/md";
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContentText from '@material-ui/core/DialogContentText';
import { toast, ToastContainer } from "react-toastify";
import { consoleLog } from "../../../../services/common-function";
import { ApiCall } from "../../../../services/middleware";
import { ErrorCode } from "../../../../services/constant";
import { Decoder } from "../../../../services/auth";

export default class SmsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            allInviteeList: [],
            allSmsContact: [],
            shareSmsText: "",
            smsBody: "",
            contactListPopUp: false,
            hideShareBtn: false,
        }
    }

    componentDidMount() {
        this.getInviteeListData()
    }

    loaderChange = async (type) => {
        this.setState({
            loader: type
        })
    }

    getInviteeListData = async () => {
        let req = {
            jobId: this.props.jobId,
            search: '',
            userType: ""
        }

        consoleLog("Get Invitee List req >>> ", req)

        let res = await ApiCall("getIviteeList", req);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            // console.log("Get job invitee response >>>> :::", payload.data);
            let arr = [];
            if (payload.data.list.length > 0) {
                for (let list of payload.data.list) {
                    list['isCheck'] = 0;
                    arr.push(list);
                }
            }
            consoleLog("Get job invitee response >>>> :::", arr)
            this.setState({
                allInviteeList: arr,
                allEmailInviteList: arr,
                allCcEmailInviteList: arr
            })
        }
    }

    closeSmsModal = () => {
        this.props.closeSms();
    }

    onChangeSms = (e) => {
        if (e.target.value != "") {
            const inputText = e.target.value;
            if (/^\d*$/.test(inputText)) {
                if (e.charCode == 13 || e.charCode == 32) {
                    let arr = this.state.allSmsContact;
                    arr.push(e.target.value);
                    this.setState({
                        allSmsContact: arr,
                        shareSmsText: ''
                    })
                } else {
                    this.setState({
                        shareSmsText: e.target.value
                    })
                }
            }
        }
    }

    changeSmsText = (e) => {
        let val = e.target.value;
        if (/^\d*$/.test(val)) {

            let temp = val.split(',');
            if (temp.length > 1) {
                let arr = this.state.allSmsContact;
                for (let i = 0; i < temp.length - 1; i++) {
                    if (temp[i] != "") {
                        arr.push(temp[i]);
                    }
                }
                this.setState({
                    allSmsContact: arr,
                })
            }
            this.setState({
                shareSmsText: temp[temp.length - 1]
            })
        }
    }

    openContactListPopup = () => {
        this.setState({
            contactListPopUp: true
        })
    }

    closeContactListPopup = () => {
        this.setState({
            contactListPopUp: false
        })
    }

    deleteSms = async (pos) => {
        let arr = this.state.allSmsContact,
            crr = this.state.allInviteeList,
            brr = [];

        crr[pos].isCheck = 0;
        arr.map((data, i) => {
            if (i != pos) {
                brr.push(data)
            }
        });
        // console.log("Length>>", brr.length)
        await this.loaderChange(true);
        this.setState({
            allSmsContact: brr,
            allInviteeList: crr
        })
        await this.loaderChange(false);
    }

    checkInvite = async (e, pos) => {
        let arr = this.state.allInviteeList;
        if (e.target.checked) {
            arr[pos].isCheck = 1;
        } else {
            arr[pos].isCheck = 0;
        }
        // console.log(arr);
        await this.loaderChange(true);
        this.setState({
            allInviteeList: arr
        })
        await this.loaderChange(false);
    }

    submitCheckInviteForSms = async () => {
        let arr = [];
        this.state.allInviteeList.map((data) => {
            if (data.isCheck === 1) {
                let temp = data.countryCode + "" + data.mobile;
                arr.push(temp);
            }
        })
        await this.loaderChange(true);
        this.setState({
            allSmsContact: arr,
            contactListPopUp: false
        })
        await this.loaderChange(false);
    }

    submitForSendSms = async () => {
        let data = {};
        if (this.state.shareSmsText === "" && this.state.allSmsContact.length === 0) {
            toast.error("Please enter a number for send sms");
        } else if (this.state.smsBody === '') {
            toast.error("SMS body cannot be blank");
        } else {
            let noteSms = "Note: Please include the relevant Job ID or Project ID in your response. If you are outside the US, kindly use the number provided below to reply alongwith relevant Job ID or Project ID.";

            if (this.state.shareSmsText === "" && this.state.allSmsContact.length > 0) {
                data = {
                    jobId: this.props.jobId,
                    body: this.state.smsBody + " \n" + noteSms,
                    to: this.state.allSmsContact,
                    fromApp: "no"
                }
                this.shareLinkApiForSms(data);
            } else {
                let arr = this.state.allSmsContact;
                arr.push(this.state.shareSmsText);
                data = {
                    jobId: this.props.jobId,
                    body: this.state.smsBody + " \n" + noteSms,
                    to: arr,
                    fromApp: "no"
                }
                this.shareLinkApiForSms(data);
            }
        }
        let arr = this.state.allInviteeList;
        arr.map((data) => {
            data.isCheck = 0;
        })
        await this.loaderChange(true);
        this.setState({
            allInviteeList: arr,
            showsms: false
        })
        await this.loaderChange(false);
    }

    shareLinkApiForSms = async (data) => {
        consoleLog("Share smsShare Link Request:", data);
        let res = await ApiCall("jobSmsSent", data);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success('SMS Request sent successfully');
            this.closeSmsModal();
            this.setState({
                hideShareBtn: false,
                allSmsContact: [],
                shareSmsText: ''
            })
        } else {
            toast.error(res.message);
            this.setState({
                hideShareBtn: false
            })
        }
    }

    changeSmsBody = (e) => {
        this.setState({
            smsBody: e.target.value
        })
    }


    render() {
        return (<>
            <Modal show={this.props.showsms}
                onHide={this.closeSmsModal}
                dialogClassName="audio-modal"
                centered
            >
                <Modal.Header id="form-dialog-title">Send Job Specific SMS</Modal.Header>
                <Modal.Body>
                    <DialogContentText>
                        To
                    </DialogContentText>
                    <input
                        type='text'
                        placeholder="Type Phone Number With Country code"
                        className="sms_numberInput"
                        value={this.state.shareSmsText}
                        onKeyPress={this.onChangeSms}
                        onChange={this.changeSmsText} />
                    <span className="contact_sms_icon" onClick={() => this.openContactListPopup()}><MdContacts size={30} /></span>

                    <div className="row">
                        {this.state.allSmsContact.map((item, i) =>
                            <div className="col-md-4" style={{ paddingTop: '10px' }}>
                                <Card>
                                    <Card.Body className="share_card_body">
                                        {"+" + item}
                                        <CloseButton style={{ float: 'right' }} onClick={() => this.deleteSms(i)} />
                                    </Card.Body>
                                </Card>

                            </div>)}
                    </div>

                    <div className="editor_pad-vri">
                        <DialogContentText>
                            Message
                        </DialogContentText>

                        <textarea className="smsBody" value={this.state.smsBody} onChange={this.changeSmsBody}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer hidden={this.state.hideShareBtn}>
                    <Button onClick={this.closeSmsModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.submitForSendSms} color="primary">
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.contactListPopUp} onHide={this.closeContactListPopup} dialogClassName="contact-mod" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {this.state.allInviteeList.map((data, i) => <>
                            <div className="col-md-1">
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    checked={data.isCheck === 1}
                                    onChange={(e) => this.checkInvite(e, i)}
                                />
                            </div>
                            <div className="col-md-11 name_con" >
                                {data.name + " ( " + data.userTypeName + " )"}
                            </div>
                        </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    {/* <Button onClick={this.closeContactListPopup} color="primary">
      Reset
    </Button> */}
                    <Button onClick={() => this.submitCheckInviteForSms()} color="primary">
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </>)
    }
}